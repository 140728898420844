import styled from 'styled-components';
import { Grid } from '@mui/material';

export const FooterStyled = styled.footer`
  background: #dadada;
  z-index: 1300;
  position: relative;
`;
export const GridFooter = styled(Grid)`
  padding: 0 16px;
  max-width: 1280px;
  margin: auto;
  align-items: center;

  ul {
    display: flex;
    gap: 36px;
    margin: 0;
    align-items: center;

    li {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.46px;
      color: #848484;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #848484;
      }
    }
  }
`;

export const Author = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  color: #848484;
  display: flex;

  & span {
    margin-right: 8px;
  }
`;
